import { getAllPageSlugs, PageQuery } from "queries/Page";
import { request } from "components/hooks/useQuery";
import { GetStaticPropsContext } from "next";
import BlockMapper from "blocks/BlockMapper";
import { createSubscription } from "components/hooks/usePreview";
import { withAppLayout } from "components/core/withAppLayout";
import CtaBlock from "../blocks/cta/CtaBlock";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import nextI18NextConfig from "../../next-i18next.config";

export async function getStaticPaths({ locales }: any) {
    const { allPages }  = await request({
        query: getAllPageSlugs
    });

    const paths: any[] = [];
    allPages.forEach((page: any) => {
        if(page.hideForThisLanugage) {
            return;
        }

        const preparedSlug = page.slug.split("/");
        locales.forEach((locale: string) => {
            paths.push({
                params: { slug: preparedSlug }, locale: locale
            });
        });
    });

    return {
        paths: paths,
        fallback: false
    };
}

export async function getStaticProps(context: GetStaticPropsContext) {
    const params = context.params as any;
    const slug = params.slug.join("/");
    const query = {
        query: PageQuery(slug, context.locale),
        preview: context.preview,
        withAppData: true,
        locale: context.locale
    };

    const data = await createSubscription(context, query);

    if (!data?.initialData?.page) {
        return {
            notFound: true,
        };
    }

    if(data.initialData.page.hideForThisLanguage) {
        return {
            notFound: true,
        };
    }
    
    const getAppData = () => {
        if(data.initialData.page.hideNavigation) {
            return ({
                ...data.initialData.footer,
            });
        }

        return ({
            ...data.initialData.header,
            ...data.initialData.footer,
        });
    };

    return {
        props: {
            data: data,
            seo: data.initialData.page.seo,
            appData: getAppData(),
            ...(await serverSideTranslations(context.locale, ['common'], nextI18NextConfig)),
        }
    };
}

function Page(props: any) {
    const { page } = props;

    return (
        <>
            {page?.content.map((block: any) => Object.keys(block).length > 0 && (
                <BlockMapper key={block.id} {...block} />
            ))}
            {page?.cta?.map((cta: any) => Object.keys(cta).length > 0 && (
                <section key={cta.id}>
                    <CtaBlock {...cta} />
                </section>
            ))}
        </>
    );
}

export default withAppLayout(Page);